<template>
  <div>
    <CCollapse :show="collapseTipoTiempoPerdido">
      <CCard>
        <CCardHeader color="dark" style="padding: 0.3rem 1rem;">
          <CRow>
            <CCol sm="10" class="d-flex ">{{ titulo}}</CCol>
             <CCol sm="2" class="d-flex justify-content-end">
                <CButton
                    @click="checkTableCollapse()"
                    size="sm"
                    v-c-tooltip="{
                      content: $t('label.listType'),
                      placement: 'top-end',
                    }"
                >
                    {{ checkTextCollapse }}
                    
                    <CIcon :name="checkIconCollapse" /> 
                </CButton>
              </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CCol sm="12">
            <CRow>
              <CCol sm="12" md="9" lg="10">
                <CInput
                  :label="$t('label.name')+' (EN)'"
                  addLabelClasses="required text-right"
                  horizontal
                  v-uppercase
                  :placeholder="$t('label.lostTimeTypeName')+' (EN)'"
                  v-model="$v.formTpTiempoPerdido.NbTpTiempoPerdidoEn.$model"
                  :is-valid="hasError($v.formTpTiempoPerdido.NbTpTiempoPerdidoEn)"
                  :invalid-feedback="errorMessage($v.formTpTiempoPerdido.NbTpTiempoPerdidoEn)"
                  required
                  maxlength="250"
                >

                </CInput>
              </CCol>
              <CCol sm="12" md="3" lg="2">
                <!--  :disabled="!idIndentification" -->
                <CButton
                  color="add"  size="sm"  class="mr-1"             
                  v-c-tooltip="{
                    content: $t('label.grabar'),
                    placement: 'top-end',
                  }"
                  @click="updatedItem()"
                >
                  <CIcon name="checkAlt" />
                </CButton>
                <CButton color="wipe" size="sm" class="mr-1"  
                  @click="resetForm()"  
                  v-c-tooltip="{
                      content: $t('label.clearFields'),
                      placement: 'top-end'
                  }"
                  >
                  <CIcon name="cil-brush-alt"/>
                </CButton>
              </CCol>
              <CCol sm="12" md="9" lg="10">
                <CInput
                  :label="$t('label.name')+' (ES)'"
                  addLabelClasses="required text-right"
                  horizontal
                  v-uppercase
                  :placeholder="$t('label.lostTimeTypeName')+' (ES)'"
                  v-model="$v.formTpTiempoPerdido.NbTpTiempoPerdidoEs.$model"                 
                  :is-valid="hasError($v.formTpTiempoPerdido.NbTpTiempoPerdidoEs)"
                  :invalid-feedback="errorMessage($v.formTpTiempoPerdido.NbTpTiempoPerdidoEs)"
                  required
                  maxlength="250"
                >
                </CInput>
              </CCol>
              <CCol sm="12" md="4" lg="3">
              </CCol>
            </CRow>
          </CCol>
          <CCollapse :show="collapseTableTipoTiempoPerdido" class="mt-2">
            <dataTableExtended
     class="align-center-row-datatable"
              :items="formatedItems"
              :fields="fields"
              sorter
              :noItemsView="tableText.noItemsViewText"
              :items-per-page="5"
              pagination
              column-filter
              hover
               
            >
              <template #loading>
                <loading/>
              </template>
              <template #TpDelayId="{ index}">
                <td class="center-cell">
                  {{ index + 1 }}
                </td>
              </template>
              <template #status="{item}">
                <td class="center-cell">
                  <CBadge v-if="item.status" color="success">
                    {{ $t("label." + item.Status) }}
                  </CBadge>
                  <CBadge v-if="!item.status" color="danger-transparante">
                    {{ $t("label." + item.Status) }}
                  </CBadge>
                </td>
              </template>
              <template #acciones="{item,index}">
                <td class="center-cell">
                    <CButton
                      color="primary-grey"
                      size="sm"
                      class="mr-1"
                      @click="updatedItemTable(item, index)"
                      v-c-tooltip="{
                        content: $t('label.edit') + $t('label.lostTime'),
                        placement: 'top-end',
                      }"
                    >
                      <CIcon name="pencil" />
                    </CButton>
                    <CButton
                      color="primary-yellow"
                      size="sm"
                      class="mr-1"
                      @click="statusItemTable(item, index)"
                      v-c-tooltip="{
                        content: $t('label.edit') + $t('label.status'),
                        placement: 'top-end',
                      }"
                    >
                      <CIcon name="cil-clock" />
                    </CButton>
                </td>
              </template>
            </dataTableExtended>
          </CCollapse>
        </CCardBody>
      </CCard>
    </CCollapse>
  </div>
</template>

<script>
import ENUM from "@/_store/enum";
import { mapState, mapMutations } from "vuex";
import { FormTpTiempoPerdido } from "@/_validations/tiempoperdido/TiempoValidations";
import UpperCase from "@/_validations/uppercase-directive";
import GeneralMixin from "@/_mixins/general";
import ModalMixin from "@/_mixins/modal";

function fields(){
  if(this.$i18n.locale=='en'){ 
    return [
      { key: "TpDelayId", label: "#", filter:false, _style: "width:1%" },
      { key: "TpDelayNameEn", label: this.$t('label.name')+' (EN)', _style: "width:65%", _classes:"text-left"  },
      { key: "status", label: this.$t('label.status'), _style: "width:10%", _classes:"text-center" },
      { key: "acciones", label: "",  _style: "width:100px", sorter: false, filter: false, _classes:"text-center" },
    ];
  }
  if(this.$i18n.locale=='es'){   
    return [
      { key: "TpDelayId", label: "#", filter:false, _style: "width:1%" },
      { key: "TpDelayNameEs", label: this.$t('label.name')+' (ES)', _style: "width:65%", _classes:"text-left"  },
      { key: "status", label: this.$t('label.status'), _style: "width:10%", _classes:"text-center" },
      { key: "acciones", label: "",  _style: "width:100px", sorter: false, filter: false, _classes:"text-center" },
    ];
  }
}
//data
function data() {
  return {
    formTpTiempoPerdido: {
      IdTpTiempoPerdido: 0,
      NbTpTiempoPerdidoEs: "",
      NbTpTiempoPerdidoEn: "",
      FgActTpTiempoPerdido: true,
    },
  };
}

//methods
function checkTableCollapse() {
  this.$store.state.tiempoperdido.collapseTableTipoTiempoPerdido = !this.$store
    .state.tiempoperdido.collapseTableTipoTiempoPerdido;
}
function closeCollapse() {
  this.$store.state.tiempoperdido.collapseTipoTiempoPerdido = false;
  this.$store.state.tiempoperdido.collapseTableTipoTiempoPerdido = false;
}
function updatedItemTable(item, index) {
  this.$store.state.tiempoperdido.idTipoTpPerdido = item.TpDelayId;
}
function statusItemTable(item, index) {
  let { TpDelayId, TpDelayNameEn, TpDelayNameEs, FgActTpDelay } = item;
  let textoMsg='';
  if(this.$i18n.locale==='en'){ textoMsg = TpDelayNameEn; }
  if(this.$i18n.locale==='es'){ textoMsg = TpDelayNameEs; }
  this.$swal
    .fire({
      text: `${this.$t('label.changeStatusQuestion')+' '} ${textoMsg}?`, //recuerda q cambia por el idioma
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#42AA91',
      cancelButtonColor: '#E1373F',
      confirmButtonText: this.$t("button.confirm"),
      cancelButtonText: this.$t("button.cancel"),
    })
    .then((result) => {
      if (result.isConfirmed) {
        FgActTpDelay = !FgActTpDelay;
        const { dispatch } = this.$store;
        dispatch("tiempoperdido/postregisterTpTiempoPerdido", {
          IdTpTiempoPerdido: TpDelayId,
          NbTpTiempoPerdidoEn: TpDelayNameEn,
          NbTpTiempoPerdidoEs: TpDelayNameEs,
          FgActTpTiempoPerdido: FgActTpDelay,
        });
        this.resetForm();
      } else {
       // this.$swal(this.$t('label.operationCancelled'));
      }
    });
}
function updatedItem() {
  try{ 
    this.$v.formTpTiempoPerdido.$touch();
    if ( this.$v.formTpTiempoPerdido.$pending || this.$v.formTpTiempoPerdido.$error)
      throw this.$t('label.errorsPleaseCheck');
    if ( this.$v.formTpTiempoPerdido.$pending || this.$v.formTpTiempoPerdido.$error)
      return;

    let {
      IdTpTiempoPerdido,
      NbTpTiempoPerdidoEn,
      NbTpTiempoPerdidoEs,
      FgActTpTiempoPerdido,
    } = this.formTpTiempoPerdido;
    const { dispatch } = this.$store;

    dispatch("tiempoperdido/postregisterTpTiempoPerdido", {
      IdTpTiempoPerdido,
      NbTpTiempoPerdidoEn,
      NbTpTiempoPerdidoEs,
      FgActTpTiempoPerdido,
    });
    this.resetForm();
   } catch (e) {
    this.notifyError({ text: e });
  }
}
function checkItem() {
  this.$v.formTpTiempoPerdido.$touch();
  if (
    this.$v.formTpTiempoPerdido.$pending ||
    this.$v.formTpTiempoPerdido.$error
  )
    return;

  let {
    IdTpTiempoPerdido,
    NbTpTiempoPerdidoEn,
    NbTpTiempoPerdidoEs,
    FgActTpTiempoPerdido,
  } = this.formTpTiempoPerdido;
  const { dispatch } = this.$store;

    let textoMsg='';
    if(this.$i18n.locale==='en'){ textoMsg = NbTpTiempoPerdidoEn; }
    if(this.$i18n.locale==='es'){ textoMsg = NbTpTiempoPerdidoEs; }
  this.$swal
    .fire({
      text: `${this.$t('label.changeStatusQuestion')+' '} ${textoMsg}?`, //cheque asi esta en español o en ingles
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#42AA91',
      cancelButtonColor: '#E1373F',
      confirmButtonText: this.$t("button.confirm"),
      cancelButtonText: this.$t("button.cancel"),
    })
    .then((result) => {
      if (result.isConfirmed) {
        FgActTpTiempoPerdido = !FgActTpTiempoPerdido;
        dispatch("tiempoperdido/postregisterTpTiempoPerdido", {
          IdTpTiempoPerdido,
          NbTpTiempoPerdidoEn,
          NbTpTiempoPerdidoEs,
          FgActTpTiempoPerdido,
        });
        this.resetForm();
      } else {
       // this.$swal("Operación cancelada");
      }
    });
}

function resetForm() {
  this.formTpTiempoPerdido.IdTpTiempoPerdido = 0;
  this.formTpTiempoPerdido.NbTpTiempoPerdidoEn = "";
  this.formTpTiempoPerdido.NbTpTiempoPerdidoEs = "";
  this.formTpTiempoPerdido.FgActTpTiempoPerdido = true;
  this.$store.state.tiempoperdido.idTipoTpPerdido = 0;

  this.$nextTick(() => {
    this.$v.$reset();
  });

}


//computed
function idIndentification() {
  return this.formTpTiempoPerdido.IdTpTiempoPerdido === 0;
}
function checkType() {
  if (this.formTpTiempoPerdido.FgActTpTiempoPerdido) {
    return "cil-trash";
  } else {
    return "cil-update";
  }
}
function checkColor() {
  if (this.formTpTiempoPerdido.FgActTpTiempoPerdido) {
    return "wipe";
  } else {
    return "add";
  }
}
function checkColorCollapse() {
  if (this.$store.state.tiempoperdido.collapseTableTipoTiempoPerdido) {
    return "add";
  } else {
    return "wipe";
  }
}
function checkIconCollapse() {
  if (this.$store.state.tiempoperdido.collapseTableTipoTiempoPerdido) {
    return "cil-chevron-top-alt";
  } else {
    return "cil-chevron-bottom-alt";
  }
}
function checkTextCollapse() {
  if (this.$store.state.tiempoperdido.collapseTableTipoTiempoPerdido) {
    return this.$t("label.close");
  } else {
    return this.$t("label.toView");
  }
}
function computedItems() {
  if(this.$i18n.locale==='en'){ 
    return this.$store.getters["tiempoperdido/myDataTpTiempoPerdidoTableEn"];
  }
  if(this.$i18n.locale==='es'){ 
    return this.$store.getters["tiempoperdido/myDataTpTiempoPerdidoTableEs"];
  }
  

}
function formatedItems() {
  const filtro = this.filtroSelected;
  return this.computedItems.filter((item) => {
    return item;
  });
}

function titulo() {
  if (this.$store.state.tiempoperdido.idTipoTpPerdido !== 0)
   { 
    if(this.$i18n.locale==='en'){ 
      return this.$t('label.edit')+' '+ this.$t('label.lostTimeType')+': '+this.formTpTiempoPerdido.NbTpTiempoPerdidoEn;
    }
    if(this.$i18n.locale==='es'){ 
      return this.$t('label.edit')+' '+ this.$t('label.lostTimeType')+': '+this.formTpTiempoPerdido.NbTpTiempoPerdidoEs;
    }
   } 
  else
  { 
    return this.$t('label.nuevo')+this.$t('label.lostTimeType');
  }
}

//watch
function collapseTipoTiempoPerdido(newQuestion, Oldquestion) {
  if (newQuestion === false) {
    this.formTpTiempoPerdido.IdTpTiempoPerdido = 0;
    this.formTpTiempoPerdido.NbTpTiempoPerdidoEn = "";
    this.formTpTiempoPerdido.NbTpTiempoPerdidoEs = "";
    this.formTpTiempoPerdido.FgActTpTiempoPerdido = true;
    this.$nextTick(() => {
      this.$v.$reset();
    });
    this.$store.state.tiempoperdido.idTipoTpPerdido = 0;
    this.$store.state.tiempoperdido.collapseTableTipoTiempoPerdido = false;
  }
}

function busqueda(newQuestion, Oldquestion) {
  if (this.collapseTipoTiempoPerdido) {
    if (newQuestion !== 0) {
      this.$store.state.tiempoperdido.apiStateForm = ENUM.LOADING;
      try {
        this.$http
          .ejecutar("GET", "TpDelay-by-id", { TpDelayId: newQuestion }, "")
          .then((response) => {
            try {
              if (response.status === 200) {
                const Information = response.data.data;
                if (Information.length !== 0) {
                  this.formTpTiempoPerdido.IdTpTiempoPerdido =
                    Information[0].TpDelayId;
                  this.formTpTiempoPerdido.NbTpTiempoPerdidoEn =
                    Information[0].TpDelayNameEn;
                    this.formTpTiempoPerdido.NbTpTiempoPerdidoEs =
                    Information[0].TpDelayNameEs;
                  this.formTpTiempoPerdido.FgActTpTiempoPerdido =
                    Information[0].FgActTpDelay;
                    this.$v.formTpTiempoPerdido.$touch();
                  this.$store.state.tiempoperdido.apiStateForm = ENUM.INIT;
                } else {
                  this.$store.state.tiempoperdido.apiStateForm = ENUM.LOADED;
                }
              } else {
                this.$store.state.tiempoperdido.apiStateForm = ENUM.ERROR;
                this.$store.commit("tiempoperdido/mutationModal", false);
              }
            } catch (err) {
              this.$store.state.tiempoperdido.apiStateForm = ENUM.ERROR;
              this.$store.commit("tiempoperdido/messageMutation", err);
              this.$store.commit("tiempoperdido/mutationModal", false);
            }
          })
          .catch((err) => {
            this.$store.commit("tiempoperdido/messageMutation", err);
            this.$store.state.tiempoperdido.apiStateForm = ENUM.ERROR;
            this.$store.commit("tiempoperdido/mutationModal", false);
          });
      } catch (error) {
        this.$store.commit("tiempoperdido/messageMutation", error);
        this.$store.state.tiempoperdido.apiStateForm = ENUM.ERROR;
        this.$store.commit("tiempoperdido/mutationModal", false);
      }
    }
  }
}

export default {
  name: "app-collapse-tipotiempoperdido",
  props: {
    LimpiarCollapse: {
      type: Boolean,
      default: false
    },
  },
  data,
  mixins:[GeneralMixin, ModalMixin],
  directives: UpperCase,
  validations() {
    return FormTpTiempoPerdido();
  },
  computed: {
    idIndentification,
    computedItems,
    formatedItems,
    checkType,
    checkColor,
    checkColorCollapse,
    checkIconCollapse,
    checkTextCollapse,
    fields,
    ...mapState({
      idValor: (state) => state.tiempoperdido.idTipoTpPerdido,
      collapseTipoTiempoPerdido: (state) =>
        state.tiempoperdido.collapseTipoTiempoPerdido,
      collapseTableTipoTiempoPerdido: (state) =>
        state.tiempoperdido.collapseTableTipoTiempoPerdido,
    }),
    titulo,
  },
  methods: {
    statusItemTable,
    updatedItemTable,
    checkTableCollapse,
    closeCollapse,
    updatedItem,
    checkItem,
    resetForm,
  },
  watch: {
    idValor: {
      deep: true,
      handler: busqueda,
    },
    collapseTipoTiempoPerdido,
    LimpiarCollapse: function(val){
      if(val){
        this.resetForm();
      }
    }
  },
};
</script>
<style lang="scss">
  .card-body .card-header {
    background-color: #5D6164 !important;
    color: #fff;
  }
  .center-cell {
  text-align: center;
  }
  .table-index {
    table {
      td {
        vertical-align: middle !important;
      }
    }
  }
</style>