import {
    onlyMayusText,
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength,
    alphaNum
} from "vuelidate/lib/validators";


export const FormTiempoPerdido = () => {
    return {
        formTiempoPerdido: {
            IdTiempoPerdido:{
                required,
            },
            DsTiempoPerdidoEn:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(250),
                onlyMayusText
            },
            DsTiempoPerdidoEs:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(250),
                onlyMayusText
            },
            TdrCode:{
                required,
                minLength: minLength(1),
                maxLength: maxLength(4),
                alphaNum
            },
            FgActTiempoPerdido:{
                required
            },
            IdTpTiempoPerdido: { required  },
        }
    }
}

export const FormTpTiempoPerdido = () => {
    return {
        formTpTiempoPerdido: {
            IdTpTiempoPerdido:{
                required,
            },
            NbTpTiempoPerdidoEs:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(250),
                onlyMayusText
            },
            NbTpTiempoPerdidoEn:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(250),
                onlyMayusText
            },
            FgActTpTiempoPerdido:{
                required
            }
        }
    }
}

